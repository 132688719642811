<template>
  <div class="page blog-post">
    <div class="wrapper">
      <div class="page-header-container">
        <PageHeader title="News" subtitle="Featured and latest news." no-margin />
        <input v-model="searchTerms" type="text" class="input search-input" maxlength="255" placeholder="Search the title of a news">
      </div>
    </div>
    <section class="news" v-if="featuredPosts.length" :hide="!!this.searchTerms">
      <div class="wrapper">
        <div class="container">
          <div class="main-post" v-if="windowWidth > 880">
            <PostItem :post="featuredPosts[0]" />
          </div>
          <div class="swiper" ref="blogPosts" v-else>
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="post in featuredPosts" :key="post.slug">
                <PostItem :post="post" size="small" />
              </div>
            </div>
          </div>
          <div class="side-posts" v-if="windowWidth > 880 && featuredPosts.length > 1">
            <div class="item" v-for="post in featuredPosts.slice(1)" :key="post.slug">
              <PostItem :post="post" size="small" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="latest-news" v-if="filteredPosts.length">
      <div class="wrapper">
        <h2 class="section-title">
          <i class="ic ic-controller"></i>
          {{ searchTerms ? 'Search results' : 'Latest news' }}
        </h2>
        <div class="container">
          <div class="item" v-for="post in filteredPosts" :key="post.id">
            <PostItem :post="post" size="small" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  collection, query, where, getDocs, orderBy, limit,
} from 'firebase/firestore';
import { Swiper } from 'swiper';
import PageHeader from '../components/PageHeader.vue';
import PostItem from '../components/PostItem.vue';
import { db } from '../api';

import 'swiper/swiper.css';

export default {
  name: 'News',
  components: {
    PageHeader,
    PostItem,
  },
  data() {
    return {
      posts: [],
      featuredPosts: [],
      searchTerms: '',
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    filteredPosts() {
      if (!this.searchTerms) {
        return this.posts;
      }
      return [
        ...this.featuredPosts,
        ...this.posts,
      ].filter((p) => p.title.toLowerCase().includes(this.searchTerms.trim().toLowerCase()));
    },
  },
  methods: {
    updateSize() {
      this.windowWidth = window.innerWidth;
      this.$nextTick(() => {
        if (!this.blogPostsSwiper && this.$refs.blogPosts) {
          this.blogPostsSwiper = new Swiper(this.$refs.blogPosts, {
            slidesPerView: 1.15,
            spaceBetween: 12,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            enabled: true,
            breakpoints: {
              620: {
                slidesPerView: 2,
                enabled: false,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
            },
          });
        }
      });
    },
    async getPosts() {
      const featuredPosts = await getDocs(query(collection(db, 'posts'), where('active', '==', true), where('featured', '==', true), limit(3)));
      this.featuredPosts = featuredPosts.docs.map((doc) => {
        const data = doc.data();
        return data;
      }).sort((a, b) => b.headline - a.headline);
      const params = [
        collection(db, 'posts'),
        where('active', '==', true),
      ];
      if (this.featuredPosts.length) {
        params.push(where('id', 'not-in', this.featuredPosts.map((post) => post.id)));
      }
      params.push(orderBy('id', 'asc'), orderBy('created_at', 'desc'), limit(12));
      const posts = await getDocs(query(...params));
      this.posts = posts.docs.map((doc) => {
        const data = doc.data();
        return data;
      });
      this.updateSize();
    },
  },
  mounted() {
    this.getPosts();
    window.addEventListener('resize', this.updateSize);
    this.updateSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSize);
  },
};
</script>

<style scoped>
.page {
  padding-top: 60px;
}

section {
  margin-bottom: 48px;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.section-title:after {
  content: '';
  display: block;
  flex-grow: 1;
  height: 1px;
  background: rgba(255, 255, 255, 0.46);
}

.page-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}

.page-header-container .search-input {
  width: calc(33.333333% - 8px);
  background-image: url(../assets/ic-search.svg);
  background-repeat: no-repeat;
  background-size: 24px auto;
  background-position: 16px center;
  padding-left: 48px
}

.news[hide] {
  display: none;
}

.news .container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.news .main-post {
  flex-grow: 1;
}

.news .side-posts {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  width: 33.33333333%;
  padding-left: 12px;
}

.news .swiper {
  width: 100%;
  margin: 6px 0 0;
}

.latest-news .container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.latest-news .item {
  width: calc(33.3333333% - 8px);
}

@media screen and (max-width: 1170px) {
  .swiper {
    margin: 0 -32px;
  }
}

@media screen and (max-width: 1024px) {
  .highlight .container {
    flex-direction: column;
    gap: 48px;
  }
  .latest-news .item {
    width: calc(50% - 6px);
  }
}

@media screen and (max-width: 880px) {
  .news .container {
    flex-direction: column;
  }
  .news .main-post {
    width: 100%;
  }
  .news .side-posts {
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin-top: 12px;
  }
  .news .side-posts .item {
    width: 49%;
  }
  .swiper {
    margin: 0 -24px;
  }
  .page-header-container {
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
  }
  .page-header-container .search-input {
    width: auto;
  }
}

@media screen and (max-width: 680px) {
  .latest-news .item {
    width: 100%;
  }
}

@media screen and (max-width: 620px) {
  .news .swiper {
    width: calc(100% + 48px);
    margin: 6px -24px 0;
  }
}

@media screen and (max-width: 480px) {
  .section-title {
    font-size: 20px;
  }
}
</style>
